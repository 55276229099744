
  import { defineComponent, ref, watch } from "vue";
  import apiClient from '@/api/apiClient'
  import { Column } from "@/models/table";
  import { getRefreshData } from "@/view_scripts/dashboardUtils";
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";;
  import Address from "@/components/shared/Address.vue";
  import { fullName, defaultPhone, defaultEmail, resourceUrl, streetWithNumber, zipCodeAndCity } from '@/utils/dataExtractors'

  export default defineComponent({
    components: { Address },
    props: {
      careReceiver: {
        type: Object,
        default: () => {}
      },
      tableItems: {
        type: Array,
        default: () => []
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    setup(props: any, { emit }) {
      const crLeadId = ref()
      const modalVisible = ref(props.visible)
      watch(() => props.visible, (newValue) => modalVisible.value = newValue, { immediate: true })

      const modalResolveAction = async (action: string) => {
        if (action === 'save') {
          const url = `/care_receivers/${props.careReceiver.id}/assign_to_lead/${crLeadId.value}`
          await apiClient.patch(url)
          emit('done', true)
        } else if (action === 'cancel') {
          emit('done', false)
        }
        crLeadId.value = ''
      }

      const rowSelectAction = (id: string) => {crLeadId.value = id}

      const crUrl = `/care_receivers/${props.careReceiver.id}`

      const leadCandidateColumns: Column[] = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "Name",
          label: "Name",
          sortable: true,
          options: {
            contentFunction: (data: any) => fullName(data.item.attributes.address),
            hrefFunction: (data: any) => resourceUrl(data.item)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.archived",
          label: "Archiviert",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "street",
          label: "Strasse",
          sortable: true,
          options: {
            contentFunction: (data: any) => streetWithNumber(data.item.attributes.address.attributes)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "location",
          label: "Ort",
          sortable: true,
          options: {
            contentFunction: (data: any) => zipCodeAndCity(data.item.attributes.address.attributes)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "phone",
          label: "Telefon",
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const add = data.item.attributes.address.attributes
              return defaultPhone(add).number
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "email",
          label: "E-Mail",
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const add = data.item.attributes.address.attributes
              return defaultEmail(add).email
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.seller.attributes.last_name",
          label: "Vertrieb",
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const seller = data.item.attributes.seller
              return fullName(seller)
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
          key: "check",
          label: "Wählen",
          sortable: false,
          options: {
            buttons: [
              {
                icon: ['fas', 'mouse-pointer'],
                action: (data: any) => rowSelectAction(data.item.id)
              }
            ]
          }
        }
      ]

      return {
        modalVisible,
        modalResolveAction,
        leadCandidateColumns,
        crLeadId,
        crUrl,
      }
    }
  })
